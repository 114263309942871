<template>
  <div class="mt-4 mb-4">
    <div class="row">
      <div class="pe-5 col-md-4">
        <p>
          {{ texto }}
        </p>
      </div>

      <div class="col-md-4 px-0">
        <img
          src="@/assets/images/icons/lavadomanos.png"
          class="img-fluid h60 me-1"
        />
        <p class="align-self-center txt-celeste txt-iconos">
          {{ $t("productos.higiene") }}
        </p>
      </div>

      <div class="col-md-4">
        <table class="table">
          <thead>
            <th colspan="2">{{ tabla.thead }}</th>
          </thead>
          <tbody>
            <tr>
              <td>{{ tabla.porcion }}</td>
              <td>{{ tabla.medida }}</td>
            </tr>
          </tbody>
          <thead>
            <th colspan="2">{{ tabla.footer }}</th>
          </thead>
        </table>
         <div class="d-flex px-0">
        <img
          src="@/assets/images/icons/lavadomanos-cuchara.png"
          class="img-fluid h60"
        />
        <p class="align-self-center txt-iconos">
          {{ medida }}
        </p>
      </div>
      </div>
    </div>
    <div class="row">
     
    </div>
    <div class="row mt-5 text-center">
      <span><em>{{ $t("productos.advertenciasTitulo") }}</em> {{ $t("productos.advertencias") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModoPreparacion2",
  props: {
    texto: String,
    textoOrientativo: String,
    tabla: Object,
    medida: String,
  },
  methods: {
    getImage(fileName) {
      return require("@/assets/images/productos/" + fileName);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";

p {
  font-size: 0.9rem;
  text-align: justify;
  margin-bottom: 0;
}
.txt-iconos {
  text-align: left !important;
  font-size: 0.9rem !important;
  margin: 0;
}

table {
  // width: 100%;
  // border: 1px solid $gris;
  background-color: $gris-claro;
  border: 1px solid white;
  text-align: center;
  font-size: small !important;
  font-weight: lighter !important;
}

thead {
  background-color: lightgray;
}

tbody {
  border: 1px solid white !important;
}

th {
  font-weight: 400;
  padding: 0.5rem 0 0.5rem 0;
}

td {
  text-align: center;
  vertical-align: middle;
  padding: 10px !important;
  // border: 1px solid $gris;
  border: 1px solid white;
  border-bottom-color: white !important;
}

table tr > td:first-child {
  text-align: center !important;
}

em {
  color: $celeste;
  font-style: normal !important;
}
</style>
